//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PageSlug',
  layout: 'docs',
  middleware({params, redirect}) {
    if (params.slug === 'index') {
      redirect('/')
    }
  },
  async asyncData({$content, store, app, params, error}) {
    const slug = params.slug || 'index'

    let doc
    try {
      doc = await $content(app.i18n.locale, slug).fetch()
    } catch (e) {
      return error({statusCode: 404, message: 'Page not found'})
    }

    const [prev, next] = await $content(app.i18n.locale)
      .only(['title', 'slug'])
      .sortBy('position', 'asc')
      .surround(slug, {before: 1, after: 1})
      .fetch()

    return {
      doc,
      prev,
      next,
    }
  },
  data() {
    return {
      msg: 'Welcome to Your Vue.js App',
      blurConfig: {
        isBlurred: true,
        opacity: 0.1,
        filter: 'blur(1.2px)',
        transition: 'all .3s linear',
      },
    }
  },
  methods: {
    toggleBlur() {
      this.blurConfig.isBlurred = !this.blurConfig.isBlurred
    },
  },
  head() {
    return {
      title: this.doc.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.doc.description,
        },
        // Open Graph
        {hid: 'og:title', property: 'og:title', content: this.doc.title},
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.doc.description,
        },
        // Twitter Card
        {hid: 'twitter:title', name: 'twitter:title', content: this.doc.title},
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.doc.description,
        },
      ],
    }
  },
}
