//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    link() {
      return `https://github.com/tolbertdesign/playground/edit/develop/content${this.document.path}${this.document.extension}`
    },
  },
}
