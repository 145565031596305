import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=02272546&"
import script from "./_slug.vue?vue&type=script&lang=js&"
export * from "./_slug.vue?vue&type=script&lang=js&"
import style0 from "./_slug.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditOnGithub: require('/opt/build/repo/components/EditOnGithub.vue').default,ArticlePrevNext: require('/opt/build/repo/components/ArticlePrevNext.vue').default,ArticleToc: require('/opt/build/repo/components/ArticleToc.vue').default})
